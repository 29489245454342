import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCol, selectRow, incrementCol, decrementCol, selectGameOver } from "./gridSlice";

export function Grid(props) {
    const { row, col } = props;

    const gridRow = useSelector(selectRow);
    const gridCol = useSelector(selectCol);
    const gameOver = useSelector(selectGameOver);
    const dispatch = useDispatch();

    useEffect(() => {
        document.querySelector(`#grid-${gridRow}-${gridCol}`).focus();
    }, [gridRow, gridCol, gameOver]);

    const handleKeyDown = (e) => {
        if (!/^[a-zA-Z]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Enter")
            return e.preventDefault();

        if (gameOver) return e.preventDefault();

        if (e.key === "Backspace") {
            e.target.classList.remove("border-slate-400");
            e.target.classList.add("border-slate-500");
            if (e.target.value === "" && gridCol > 1) {
                return dispatch(decrementCol());
            }
        }

        if (e.key !== "Enter" && e.key !== "Backspace" && e.target.value !== "") {
            e.target.classList.remove("border-slate-500");
            e.target.classList.add("border-slate-400");
            dispatch(incrementCol());
        }
    };

    const handleKeyUp = (e) => {
        // May differ for mobile.
        if (!/^[a-zA-Z]$/.test(e.target.value) && e.key !== "Backspace" && e.key !== "Enter") {
            e.target.value = "";
            return e.preventDefault();
        }

        if (e.key === "Backspace") {
            e.target.classList.remove("border-slate-400");
            e.target.classList.add("border-slate-500");
            e.target.value = "";
        }

        if (e.key !== "Enter" && e.key !== "Backspace") {
            e.target.classList.remove("border-slate-500");
            e.target.classList.add("border-slate-400");
            dispatch(incrementCol());
        }
    };

    const handleUserClick = (e) => {
        const gridInput = document.querySelector(`#grid-${gridRow}-${gridCol}`);
        gridInput.focus();
    };

    // On mobile disables 'next" button to skip grids.
    const handleOnBlur = (e) => {
        // Prevent scroll so that user can select other elements.
        if (e.target.value === "")
            document.querySelector(`#grid-${gridRow}-${gridCol}`).focus({ preventScroll: true });
    };

    return (
        <input
            id={`grid-${row}-${col}`}
            type="text"
            maxLength={1}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onClick={handleUserClick}
            onBlur={handleOnBlur}
            disabled={gameOver}
            autoComplete="off"
            className="m-1 w-10 h-10 text-xl md:w-14 md:h-14 md:text-4xl xl:w-20 xl:h-20 xl:text-5xl text-center font-bold uppercase bg-transparent border-2 border-slate-500 text-zinc-300 focus:outline-none caret-transparent select-none"
        />
    );
}
