import { Grid } from "../grid/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
    incrementRow,
    selectGameStarted,
    selectRow,
    setGameStarted,
    setGuessedWord,
} from "../grid/gridSlice";

export function Row(props) {
    const { word, row } = props;

    const gridRow = useSelector(selectRow);
    const gameStarted = useSelector(selectGameStarted);
    const dispatch = useDispatch();

    const renderGrids = () => (
        <form onSubmit={onSubmit}>
            {Array.from({ length: word.length }, (_, i) => i).map((i) => {
                return <Grid key={i} row={row} col={i + 1} />;
            })}
            <input className="text-transparent w-0" type="submit" value="send" />
        </form>
    );

    const onSubmit = (e) => {
        e.preventDefault();
        let guessedWordInCharacters = [];

        const gridInputs = document.querySelectorAll(
            `div.animated.bounceIn > div > div:nth-child(${gridRow}) > form > input[type="text"]`
        );

        gridInputs.forEach((gridInput) =>
            guessedWordInCharacters.push(gridInput.value.toLowerCase())
        );
        if (guessedWordInCharacters.includes("")) return;

        startGame(guessedWordInCharacters);
    };

    const startGame = (guessedWordInCharacters) => {
        // Game is started...
        if (!gameStarted) dispatch(setGameStarted(true));

        // Assign the guessed word to the state.
        dispatch(setGuessedWord(guessedWordInCharacters.join("")));

        // Assign background color to the guessed characters.
        // Borrowed from: https://github.com/chantastic/compare-words/blob/main/index.mjs
        for (let i = 0; i < word.length; i++) {
            const gridInput = document.querySelector(`#grid-${gridRow}-${i + 1}`);

            // Create a new array with the guessed characters.
            let zippedLetters = Array.from(word, (_, i) => [word[i], guessedWordInCharacters[i]]);

            // Check if the guessed character exists within the available letter pool.
            let availableLetterPool = zippedLetters.reduce(
                (accumulator, [answerLetter, guessLetter]) =>
                    answerLetter === guessLetter
                        ? accumulator
                        : {
                              ...accumulator,
                              [answerLetter]: accumulator[answerLetter] + 1 || 1,
                          },
                {}
            );

            // Return the right condition for each character.
            let result = zippedLetters.map(([answerLetter, guessLetter]) => {
                if (guessLetter === answerLetter) {
                    return [guessLetter, "correct"];
                }
                if (word.includes(guessLetter) && availableLetterPool[guessLetter]) {
                    availableLetterPool[guessLetter] = availableLetterPool[guessLetter] - 1;
                    return [guessLetter, "present"];
                }
                return [guessLetter, "absent"];
            });

            // Assign the background color to the guessed characters.
            switch (result[i][1]) {
                case "correct":
                    gridInput.classList.remove("bg-transparent");
                    gridInput.classList.remove("border-slate-500");
                    gridInput.classList.remove("border-slate-400");
                    gridInput.classList.add("bg-green-600");
                    gridInput.classList.add("border-transparent");
                    break;
                case "present":
                    gridInput.classList.remove("bg-transparent");
                    gridInput.classList.remove("border-slate-500");
                    gridInput.classList.remove("border-slate-400");
                    gridInput.classList.add("bg-yellow-600");
                    gridInput.classList.add("border-transparent");
                    break;
                case "absent":
                    gridInput.classList.remove("bg-transparent");
                    gridInput.classList.remove("border-slate-500");
                    gridInput.classList.remove("border-slate-400");
                    gridInput.classList.add("bg-zinc-600");
                    gridInput.classList.add("border-transparent");
                    break;
            }
        }

        // Go to next row AKA new attempt.
        dispatch(incrementRow());
    };

    return <div className="flex">{renderGrids()}</div>;
}
