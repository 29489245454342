import { useDispatch, useSelector } from "react-redux";
import { selectGameStarted, selectLevel, setLevel } from "../grid/gridSlice";

export default function Level(props) {
    const { name, bgColor } = props;

    const gameStarted = useSelector(selectGameStarted);
    const getLevel = useSelector(selectLevel);
    const dispatch = useDispatch();

    const changeLevel = (e) => {
        e.preventDefault();
        dispatch(setLevel(name));
    };

    return (
        <button
            className={`w-full mx-2 md:mx-2 ${bgColor} text-zinc-300 font-bold py-2 px-4 rounded-full disabled:opacity-50 disabled:cursor-not-allowed`}
            onClick={changeLevel}
            disabled={gameStarted || getLevel === name}>
            {name}
        </button>
    );
}
