import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    row: 1,
    col: 1,
    word: "",
    wordDescription: "",
    wordThumbsUp: 0,
    wordThumbsDown: 0,
    wordPermaLink: "",
    guessedWord: "",
    gameOver: false,
    gameStarted: false,
    attemptNumber: 1,
    level: "Beginner",
    minLetters: 3,
    maxLetters: 5,
    maxAttemptNumber: 6,
    isLoading: false,
};

export const gridSlice = createSlice({
    name: "grid",
    initialState,
    reducers: {
        setRow: (state, action) => {
            state.row = action.payload;
        },
        setCol: (state, action) => {
            state.col = action.payload;
        },
        setWord: (state, action) => {
            state.word = action.payload;
        },
        setWordDescription: (state, action) => {
            state.wordDescription = action.payload;
        },
        setWordThumbsUp: (state, action) => {
            state.wordThumbsUp = action.payload;
        },
        setWordThumbsDown: (state, action) => {
            state.wordThumbsDown = action.payload;
        },
        setWordPermaLink: (state, action) => {
            state.wordPermaLink = action.payload;
        },
        incrementCol: (state) => {
            if (state.word.length > state.col) {
                state.col += 1;
            }
        },
        decrementCol: (state) => {
            if (state.col <= state.word.length) {
                state.col -= 1;
            }
        },
        incrementRow: (state) => {
            if (state.attemptNumber < state.maxAttemptNumber) {
                state.row += 1;
                state.attemptNumber += 1;
                state.col = 1;
            } else {
                state.gameOver = true;
            }
        },
        setAttemptNumber: (state, action) => {
            state.attemptNumber = action.payload;
        },
        setMaxAttemptNumber: (state, action) => {
            state.maxAttemptNumber = action.payload;
        },
        setGuessedWord: (state, action) => {
            state.guessedWord = action.payload;
        },
        setGameOver: (state, action) => {
            state.gameOver = action.payload;
        },
        setGameStarted: (state, action) => {
            state.gameStarted = action.payload;
        },
        setLevel: (state, action) => {
            state.level = action.payload;

            switch (state.level) {
                case "Beginner":
                    state.minLetters = 3;
                    state.maxLetters = 5;
                    state.maxAttemptNumber = 6;
                    break;
                case "Intermediate":
                    state.minLetters = 6;
                    state.maxLetters = 8;
                    state.maxAttemptNumber = 5;
                    break;
                case "Expert":
                    state.minLetters = 9;
                    state.maxLetters = 12;
                    state.maxAttemptNumber = 4;
                    break;
                default:
                    state.minLetters = 3;
                    state.maxLetters = 5;
                    state.maxAttemptNumber = 6;
                    break;
            }
        },
        resetEverything: (state) => {
            state.row = 1;
            state.col = 1;
            state.word = "";
            state.wordDescription = "";
            state.wordThumbsUp = 0;
            state.wordThumbsDown = 0;
            state.wordPermaLink = "";
            state.guessedWord = "";
            state.gameOver = false;
            state.gameStarted = false;
            state.attemptNumber = 1;
            state.level = "Beginner";
            state.minLetters = 3;
            state.maxLetters = 5;
            state.maxAttemptNumber = 6;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const {
    setRow,
    setCol,
    setWord,
    setWordDescription,
    setWordThumbsUp,
    setWordThumbsDown,
    setWordPermaLink,
    incrementCol,
    decrementCol,
    incrementRow,
    setAttemptNumber,
    setMaxAttemptNumber,
    setGuessedWord,
    setGameOver,
    setGuessedWordArray,
    setGameStarted,
    setLevel,
    setIsLoading,
    resetEverything,
} = gridSlice.actions;

export const selectRow = (state) => state.grid.row;
export const selectCol = (state) => state.grid.col;
export const selectWord = (state) => state.grid.word;
export const selectWordDescription = (state) => state.grid.wordDescription;
export const selectWordThumbsUp = (state) => state.grid.wordThumbsUp;
export const selectWordThumbsDown = (state) => state.grid.wordThumbsDown;
export const selectWordPermaLink = (state) => state.grid.wordPermaLink;
export const selectGuessedWord = (state) => state.grid.guessedWord;
export const selectGameOver = (state) => state.grid.gameOver;
export const selectGameStarted = (state) => state.grid.gameStarted;
export const selectAttemptNumber = (state) => state.grid.attemptNumber;
export const selectLevel = (state) => state.grid.level;
export const selectMinLetters = (state) => state.grid.minLetters;
export const selectMaxLetters = (state) => state.grid.maxLetters;
export const selectMaxAttemptNumber = (state) => state.grid.maxAttemptNumber;
export const selectIsLoading = (state) => state.grid.isLoading;

export default gridSlice.reducer;
