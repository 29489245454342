import { Row } from "./features/row/Row";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import { BiLoaderAlt } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import {
    selectAttemptNumber,
    selectCol,
    selectGameOver,
    selectGameStarted,
    selectGuessedWord,
    selectIsLoading,
    selectLevel,
    selectMaxAttemptNumber,
    selectMaxLetters,
    selectMinLetters,
    selectRow,
    selectWord,
    selectWordDescription,
    selectWordPermaLink,
    setGameOver,
    setIsLoading,
    setWord,
    setWordDescription,
    setWordPermaLink,
    setWordThumbsDown,
    setWordThumbsUp,
} from "./features/grid/gridSlice";

import axios from "axios";
import Level from "./features/level/Level";
import Modal from "./features/modal/Modal";
import Confetti from "react-dom-confetti";

import "react-toastify/dist/ReactToastify.css";

const MINIMUM_THUMBS_UP_FOR_WORD = 500;
const MAXIMUM_WORD_DEFINITION_LENGTH = 200;

function App() {
    const gridRow = useSelector(selectRow);
    const gridCol = useSelector(selectCol);
    const gameStarted = useSelector(selectGameStarted);
    const correctWord = useSelector(selectWord);
    const correctWordDescription = useSelector(selectWordDescription);
    const correctWordPermaLink = useSelector(selectWordPermaLink);
    const guessedWord = useSelector(selectGuessedWord);
    const gameOver = useSelector(selectGameOver);
    const minLetters = useSelector(selectMinLetters);
    const maxLetters = useSelector(selectMaxLetters);
    const maxAttemptNumber = useSelector(selectMaxAttemptNumber);
    const getLevel = useSelector(selectLevel);
    const isLoading = useSelector(selectIsLoading);
    const getAttemptNumber = useSelector(selectAttemptNumber);
    const dispatch = useDispatch();

    const confettiConfig = {
        angle: "45",
        spread: "90",
        startVelocity: "100",
        elementCount: "200",
        dragFriction: "0.10",
        duration: "500",
        stagger: "1",
        width: "10px",
        height: "10px",
        perspective: "700px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    };

    const toastConfig = useMemo(() => {
        return {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "bg-zinc-900 text-white font-bold text-center",
        };
    }, []);

    const getRandomWord = useCallback(() => {
        dispatch(setIsLoading(true));
        axios.get("https://api.urbandictionary.com/v0/random").then((res) => {
            const firstRandomWord = res.data.list[0];
            if (!firstRandomWord) return dispatch(setWord("noapi"));

            const { word, definition, permalink, thumbs_up, thumbs_down } = firstRandomWord;
            if (thumbs_up < thumbs_down && thumbs_up < MINIMUM_THUMBS_UP_FOR_WORD)
                return getRandomWord();
            if (definition.length > MAXIMUM_WORD_DEFINITION_LENGTH) return getRandomWord();
            if (!word.match(/^[a-zA-Z]+$/)) return getRandomWord();
            if (word.length < minLetters || word.length > maxLetters) return getRandomWord();

            dispatch(setWord(word.toLowerCase()));
            dispatch(setWordDescription(definition));
            dispatch(setWordPermaLink(permalink));
            dispatch(setWordThumbsUp(thumbs_up));
            dispatch(setWordThumbsDown(thumbs_down));
            dispatch(setIsLoading(false));

            console.log("CORRECT WORD:", word.toLowerCase());
        });
    }, [dispatch, minLetters, maxLetters]);

    const resetGame = useCallback(() => {
        for (let i = 0; i <= gridRow; i++) {
            const inputs = document.querySelectorAll(
                `div.animated.bounceIn > div > div:nth-child(${i}) > form > input[type="text"]`
            );

            inputs.forEach((input) => {
                input.value = "";
                input.classList.remove("bg-zinc-600");
                input.classList.remove("bg-yellow-600");
                input.classList.remove("bg-green-600");
                input.classList.remove("border-transparent");
                input.classList.add("border-slate-500");
                input.classList.add("bg-transparent");
            });
        }
    }, [gridRow]);

    const onWin = useCallback(() => {
        dispatch(setGameOver(true));
        toast.success("You won!", toastConfig);
    }, [dispatch, toastConfig]);

    const onLose = useCallback(() => {
        toast.error("You lost!", toastConfig);
    }, [toastConfig]);

    useEffect(() => {
        if (gameStarted && correctWord === guessedWord) onWin();
    }, [correctWord, guessedWord, gameStarted, getAttemptNumber, maxAttemptNumber, onWin]);

    useEffect(() => {
        if (gameOver && getAttemptNumber === maxAttemptNumber && guessedWord !== correctWord)
            onLose();
    }, [onLose, gameOver, getAttemptNumber, maxAttemptNumber, guessedWord, correctWord]);

    useEffect(() => {
        if (!gameStarted) {
            resetGame();
            getRandomWord();
        }
    }, [getRandomWord, resetGame, gameStarted, getLevel]);

    const handleUserClick = (e) => {
        if (gameOver) return;

        const gridInput = document.querySelector(`#grid-${gridRow}-${gridCol}`);
        if (!gridInput) return;

        gridInput.focus();
    };

    return (
        <>
            <Animated
                animationIn="bounceIn"
                animationOut="fadeOut"
                isVisible={gameOver}
                animationInDelay={2000}
                animationOutDelay={2000}
                className="fixed flex inset-0 z-40">
                <Modal
                    title={correctWord}
                    description={correctWordDescription}
                    permalink={correctWordPermaLink}
                    isOpen={gameOver}
                />
            </Animated>
            <div className={`min-h-screen bg-slate-800 overflow-hidden`}>
                <Animated
                    animationIn="slideInDown"
                    animationOut="slideOutUp"
                    animationOutDelay={2000}
                    animateOnMount={false}
                    isVisible={!gameOver}>
                    <Link to="/about">
                        <FaInfoCircle className="m-6 md:m-12 absolute text-4xl text-zinc-300 hover:text-blue-300" />
                    </Link>
                    <div
                        className="flex flex-col items-center h-screen pt-5"
                        style={{ userSelect: "none" }}
                        onClick={handleUserClick}>
                        <Animated
                            animationIn="bounceInDown"
                            animationOut="fadeOut"
                            isVisible={true}>
                            <h1 className="w-full text-center text-5xl md:text-6xl xl:text-7xl font-bold text-zinc-300">
                                Wordle
                            </h1>
                        </Animated>
                        <Animated
                            animationIn="bounceInLeft"
                            animationOut="fadeOut"
                            isVisible={true}>
                            <div className="flex flex-row my-6 md:my-12">
                                <Level name="Beginner" bgColor="bg-green-600 hover:bg-green-700" />
                                <Level
                                    name="Intermediate"
                                    bgColor="bg-yellow-600 hover:bg-yellow-700"
                                />
                                <Level name="Expert" bgColor="bg-red-600 hover:bg-red-700" />
                            </div>
                        </Animated>
                        {isLoading && (
                            <BiLoaderAlt className="h-24 w-24 mx-auto my-24 text-zinc-300 animate-spin" />
                        )}
                        <Animated
                            animationIn="bounceIn"
                            animationOut="fadeOut"
                            isVisible={!isLoading}>
                            {!isLoading && (
                                <div className="flex flex-col" style={{ pointerEvents: "none" }}>
                                    {Array.from({ length: maxAttemptNumber }, (_, i) => i).map(
                                        (i) => {
                                            return <Row key={i} word={correctWord} row={i + 1} />;
                                        }
                                    )}
                                </div>
                            )}
                        </Animated>
                    </div>
                    <Confetti
                        active={gameOver && guessedWord === correctWord}
                        config={confettiConfig}
                    />
                </Animated>
            </div>
            <ToastContainer />
        </>
    );
}

export default App;
