import { useDispatch } from "react-redux";
import { resetEverything } from "../grid/gridSlice";

import Highlighter from "react-highlight-words";

export default function Modal(props) {
    const { title, description, permalink, isOpen } = props;

    const dispatch = useDispatch();

    return (
        <>
            {isOpen && (
                <div className="m-auto bg-zinc-900 p-12 min-w-[90%] md:min-w-[50%] max-w-[50%]">
                    <div className="justify-center flex flex-col items-center">
                        <h2 className="font-bold text-center text-3xl md:text-4xl underline text-blue-300">
                            <a href={permalink} target="_blank" rel="noreferrer nofollow">
                                {title.toUpperCase()}
                            </a>
                        </h2>
                        <h3 className="text-white font-semibold text-center text-xl md:text-2xl mt-12 italic">
                            <Highlighter
                                searchWords={description.match(/(?<=\[)[^\][]*(?=])/g) || []}
                                autoEscape={true}
                                textToHighlight={description.replace(/\[/g, "").replace(/\]/g, "")}
                            />
                        </h3>
                        <div className="justify-center flex flex-row items-center pt-12">
                            <button
                                onClick={() => {
                                    dispatch(resetEverything());
                                }}
                                className="text-zinc-300 font-bold py-2 px-4 rounded-full bg-green-600 hover:bg-green-700">
                                Play Again
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
