import { Link } from "react-router-dom";
import { FaReact } from "react-icons/fa";
import { ImClock } from "react-icons/im";
import { Animated } from "react-animated-css";
import { useEffect, useState } from "react";
import { SiRedux, SiTailwindcss } from "react-icons/si";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import file from "../CHANGELOG.md";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

import "github-markdown-css";

export default function About() {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(file)
            .then((response) => response.text())
            .then((text) => setMarkdown(text));
    }, []);

    return (
        <div className="min-h-screen bg-slate-800 overflow-x-hidden">
            <Link to="/">
                <BsFillArrowLeftCircleFill className="z-50 m-6 md:m-12 absolute text-4xl text-zinc-300 hover:text-blue-300" />
            </Link>
            <div className="flex flex-col pt-5 max-w-4xl mx-auto">
                <Animated animationIn="bounceInDown" animationOut="fadeOut" isVisible={true}>
                    <h1 className="w-full text-center text-3xl md:text-5xl xl:text-6xl font-bold text-zinc-300">
                        Project Details
                    </h1>
                    <h2 className="w-full text-center text-2xl md:text-4xl xl:text-5xl font-semibold text-zinc-300 my-6 md:my-12">
                        Wordle
                    </h2>
                </Animated>
                <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                    <div className="flex my-3 md:my-6 p-5 w-full">
                        <div className="flex flex-col w-full text-center">
                            <FaReact className="text-5xl text-blue-500 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">React</span>
                        </div>
                        <div className="flex flex-col w-full text-center">
                            <SiRedux className="text-5xl text-purple-500 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">Redux</span>
                        </div>
                        <div className="flex flex-col w-full text-center">
                            <SiTailwindcss className="text-5xl text-blue-400 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">
                                Tailwind CSS
                            </span>
                        </div>
                        <div className="flex flex-col w-full text-center">
                            <ImClock className="text-5xl text-zinc-400 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">&#60; 48H</span>
                        </div>
                    </div>
                    <div className="p-4 mx-4 text-zinc-300 bg-zinc-900 rounded-lg shadow">
                        <div className="h-3 text-3xl text-left">“</div>
                        <p className="px-4 text-md text-center">
                            Wordle is a word search game. It is a fun game to play and it is also a
                            good way to practice your vocabulary. The game is played by typing the
                            words into the grid. The game is won when all the words are found, and
                            lost when no attempts are left.
                            <br />
                            <br />I have created this game as a way to practice my React skills and
                            to learn more about React. I have also learned about Redux and Tailwind
                            CSS to make the game look and feel great. I hope you enjoy the game!
                        </p>
                        <div className="h-3 text-3xl text-right">”</div>
                    </div>
                </Animated>
                <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                    <div className="p-6 my-4 md:my-8 mx-4 text-gray-800 bg-zinc-900 rounded-lg shadow">
                        <div className="markdown-body bg-transparent">
                            <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
                        </div>
                    </div>
                </Animated>
            </div>
        </div>
    );
}
